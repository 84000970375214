import React from 'react';
import { graphql } from 'gatsby';

import Website from 'components/ui-setup';
import Layout from 'components/layout';
import InformationSecurity from 'components/information-security';

type Props = {
  data: Object,
};

const InformationSecurityPolicyPage = ({ data }: Props) => {
  const {
    informationSecurityJson,
    logoJson,
    socialMediaJson,
    siteJson,
    cookieConsentJson,
  } = data;

  return (
    <Website
      content={{
        ...informationSecurityJson,
        menu: siteJson?.content,
        cookieConsent: cookieConsentJson,
        logo: logoJson,
        socialMedia: socialMediaJson,
      }}>
      <Layout>
        <InformationSecurity />
      </Layout>
    </Website>
  );
};

export default InformationSecurityPolicyPage;

export const query = graphql`
  query InformationSecurityPageQuery {
    informationSecurityJson {
      hero {
        background {
          image {
            xl
            lg
            md
            sm
            xs
          }
        }
        title
      }
      informationSecurity {
        text
      }
      meta {
        description
        title
      }
    }
    siteJson {
      content {
        whoWeAre {
          id
          title
          type
          position
          visible
          menuItems {
            ourLeadership {
              id
              link
              title
              type
              visible
            }
            vision {
              id
              link
              title
              type
              visible
            }
          }
        }
        whatWedo {
          id
          title
          type
          position
          visible
          menuItems {
            industries {
              id
              link
              title
              type
              visible
            }
            models {
              id
              link
              title
              type
              visible
            }
            practices {
              id
              link
              title
              type
              visible
            }
          }
        }
        techProfessionals {
          id
          link
          position
          title
          type
          visible
        }
        careers {
          id
          link
          position
          title
          type
          visible
        }
        blog {
          id
          link
          position
          title
          type
          visible
        }
        contact {
          id
          link
          position
          title
          type
          visible
        }
      }
    }
    cookieConsentJson {
      message
      buttons {
        accept
        reject
      }
    }
    logoJson {
      colorful
      white
    }
    socialMediaJson {
      label
      media {
        id
        image
        url
      }
    }
  }
`;
